.panel-group {

  .panel {
    border: none;
    border-radius: 0;
    box-shadow: none;
    
    +.panel {
      margin-top: 10px;
    }
  }

  .panel-heading {
    background-color: $brand-tertiary;
    border: none;
    border-radius: 0;
    position: relative;
    height: 40px;
    .panel-title {
      text-transform: none;
      color: $brand-primary;

      i {
        font-size: $fz-small;
        padding-right: 6px;
      }
      a {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 11px 11px 11px 11px;
        background: $brand-tertiary;
        text-decoration: none;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        border-bottom: 0;

        .glyphicon {
          margin-left: 0;
          //position: absolute;
          //top: 10px;
          //left: 10px;
        }
        .glyphicon-minus {
          display: none;
          opacity: 0;
        }
        &[aria-expanded="true"] {
          color: $white;
          background: $brand-primary;

          .glyphicon-plus {
            opacity: 0;
            display: none;
            //transform: rotate(-45deg);
            //transition: all $transition-duration-default $transition-duration-default;
          }
          .glyphicon-minus {
            display: inline-block;
            opacity: 1;
            //transition: all $transition-duration-default $transition-duration-default;
          }
        }
      }
    }
  }

  .panel-collapse {

    &.in {

    }

    .panel-body {
      padding-left: 0;
      border: 0 !important;
    }
  }
}
