.portaltype-subsitefolder.subsite-type-central_other {
  .content-header-subhome .padding-default {
    background-color: $brand-primary;
  }
}

$faculty_names: 'lw', 're', 'we', 'ge', 'ea', 'eb', 'di', 'pp', 'bw', 'fw', 'ps';

.portaltype-subsitefolder.subsite-type-faculty,
.portaltype-subsitefolder.subsite-type-department,
.portaltype-subsitefolder.subsite-type-research_group {
  @for $i from 1 through length($faculties) {
    &.section-#{nth($faculty_names, $i)} {
      .content-header-subhome .padding-default {
        background-color: nth($faculties, $i);
      }
      .date-container {
        border-color: nth($faculties, $i);

        &:after,
        &:before,
        span.month {
          border-color: nth($faculties, $i);
        }
      }
    }
  }
}

@for $i from 1 through length($faculties) {
  &.section-#{nth($faculty_names, $i)} {
    .faculty-colored {
      background-color: nth($faculties, $i);
    }
    @media(max-width: $screen-sm-min){
      .faculty-colored-xs {
        background-color: nth($faculties, $i);
      }
    }
  }
}
