/* shorten titles for nextprevious navigation btns (PCM-932) */

.nextprevious {
  .btn {
    max-width: 27em;
  }

  .pull-right .btn {
    padding-right: 2em;
  }

  .panno-arrow-right.pull-right {
    margin-right: -1.1em;
  }
}


//Style new site button in search results (PCM-1244)
.btn-site {
  margin: 5px 5px 5px 0;
  font-family: $light-font-family;
  height: 20px;
  line-height: 1px;
  font-family: "PannoTextLight",Arial,sans-serif;
  font-size: 14px;
  background: #eee;
  color: #666;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-left: 5px;
  padding-top: 10px;
  border-bottom: 0;
}

.result-item .breadcrumb a {
	&.btn-site {
		border-bottom: 0;

		&:hover{
			color: white;
		}
	} 
}

.btn-site {
  .fa-home {
    margin-left: 0;
    line-height: 1px;
  }
}