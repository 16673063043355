// fix blurry single image in slideshow portlet (PCM-1282)
.image-container {
  	.thumbnail {

  		text-align: center;

  		img {
  			width: auto;
  		}
  	}
}

#content .lead {
	margin-bottom: 30px;
}

#ugent-behavior-social {
	a {
		border-bottom: none;
	}
	&>div:not(:first-child) {
		margin-top: 10px;
	}

}

// table style listing (PCM-2059)
.listing {
	tr:nth-child(odd) td { background-color: #eee !important }
	tr:nth-child(even) td { background-color: #fff !important }

	td {
		border: 1px solid #ddd;
	}

	th.tablesorter-header {
		background-repeat: no-repeat;
		background-position: center right;
		padding: 4px 18px 4px 4px;
		white-space: normal;
		cursor: pointer;
		background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
		&.tablesorter-headerAsc {
			background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
		}
		&.tablesorter-headerDesc {
			background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
		}	
	}
}

