h1.documentFirstHeading {
  margin-top: 0;
}

b {
  font-family: $medium-font-family;
  font-weight: normal;
}

#content, body#tinymce {
  h2, .h2,
  h3, .h3 {
    text-transform: none;
    .bg-primary & {
      color: $white;
    }
  }
  a.h3, a .h3, a h2 {
    color: $brand-primary;
  }
  h2, .h2 {
    font-size: 24px;
  }
  a {
    border-bottom: none;
    text-decoration: underline;
    text-underline-offset: 0.25em;
  }
  a.btn, ul.pagination a, // text based links where no underlining is wanted. like buttons.
  a:hover {
    text-decoration: none;
  }
  ul li, ol li {
    margin: 12px 0;
  }
}
header.pageheader {
  a, a:hover, a:focus {
    border-bottom: none !important;
    text-decoration: none !important;
    & span {
      border-bottom: none !important;
      text-decoration: none !important;  
    }
  }
}

div#ppms_cm_popup_overlay *, div#ppms_cm_privacy_settings * {
  font-family: "PannoTextLight",Arial,sans-serif !important;
  font-size: 17px !important;
}
button#ppms_cm_privacy_settings_button {
  font-family: "PannoTextMedium",Arial,sans-serif !important;
  font-size: 17px !important;
}
