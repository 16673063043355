/* Generated by Glyphter (http://www.glyphter.com) on  Thu Apr 18 2024 */
@font-face {
    font-family: 'Glyphter';
    src: url('../fonts/font-awesome/Glyphter.eot?v=20240418');
    src: url('../fonts/font-awesome/Glyphter.eot?v=20240418') format('embedded-opentype'),
         url('../fonts/font-awesome/Glyphter.woff?v=20240418') format('woff'),
         url('../fonts/font-awesome/Glyphter.ttf?v=20240418') format('truetype'),
         url('../fonts/font-awesome/Glyphter.svg?v=20240418#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='fa6-']:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
//    line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.fa6-x-twitter:before{content:'\0041';}
.fa6-threads:before{content:'\0042';}