.banner {
  position: relative;
  min-height: 180px;

  a,
  i {
    color: $white;
  }
  &:hover,
  &:focus {
    .read-more {

      span {
        border-bottom: 1px solid $white;
      }
    }
  }

  .content-container {
    padding: 20px 20px 25px;
  }

  .read-more {
    position: absolute;
    left: 20px;
    bottom: 20px;
    border-bottom: 0;
    span {
      border-bottom: 1px solid transparent;
    }
  }

  .h1 {
    font-size: 35px;
    line-height: 35px;
    margin: 0;
    max-height: 105px;
    overflow: hidden;
  }
  .block-link {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 0;



  }
  .image-container {
    position: relative;
    padding-bottom: 100px;
    .bg-image-container {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100px;
      width: 100%;
      background-size: cover;
    }

    @media(min-width: $screen-sm-min) {
      .size-wide &,
      .size-wider & {
        position: absolute;
        right: 0;
        top: 0;
        height: 134px;
        width: calc(67% - 20px);
        .bg-image-container {
          height: 134px;
        }
      }
    }
  }

  &.flexible {
    min-height: 0;

    .content-container {
      padding-bottom: 50px;
    }
  }

  &.bg-tertiary {
    &:hover,
    &:focus {
      .read-more {
        span {
          border-bottom: 1px solid $brand-primary;
        }
      }
    }
    .read-more,
    i {
      color: $brand-primary;
    }
  }

  &.has-image {
    .read-more {
      @media(max-width: $screen-xs-max) {
        display: none;
      }
    }
    .content-container {
      @media(min-width: $screen-sm-min) {
        .size-wide &,
        .size-wider &, {
          width: calc(33% + 20px);
        }
      }
    }
  }
}
