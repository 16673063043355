//
// Checkboxes
// --------------------------------------------------


$font-family-icon: 'Glyphicons Halflings' !default;
$fa-var-check: "\e013" !default;
$check-icon: $fa-var-check !default;

@mixin checkbox-variant($parent, $bordercolor, $inputcolor) {
  #{$parent} input[type="checkbox"]:checked + label,
  #{$parent} input[type="radio"]:checked + label {
    &::before {
      background-color: $white;
      border-color: $bordercolor;
    }
    &::after{
      color: $inputcolor;
    }
  }
}


.checkbox {
  padding-left: 20px;

  label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      margin-left: -20px;
      border: 2px solid $brand-primary;
      background-color: #fff;
      @include transition(border 0.15s ease-in-out, color 0.15s ease-in-out);
    }

    &::after {
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 0;
      margin-left: -20px;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: $brand-primary;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    opacity: 0;
    z-index: 1;

    &:focus + label::before {
      @include tab-focus();
    }

    &:checked + label::after {
      font-family: $font-family-icon;
      content: $check-icon;
    }

    &:disabled + label {
      opacity: 0.65;

      &::before {
        background-color: $input-bg-disabled;
        cursor: not-allowed;
      }
    }

  }

  &.checkbox-circle label::before {
    border-radius: 50%;
  }

  &.checkbox-inline {
    margin-top: 0;
  }
}
@include checkbox-variant('.checkbox-primary', $brand-primary, $brand-primary);

//
// Radios
// --------------------------------------------------

@mixin radio-variant($parent, $bordercolor, $inputcolor) {
  #{$parent} input[type="radio"]{
    + label{
      &::after{
        background-color: $white;
      }
    }
    &:checked + label{
      &::before {
        border-color: $bordercolor;
      }
      &::after{
        background-color: $inputcolor;
      }
    }
  }
}

.radio{
  padding-left: 20px;

  label{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &::before{
      content: "";
      display: inline-block;
      position: absolute;
      width: 18px;
      height: 18px;
      left: 0;
      margin-left: -20px;
      border: 2px solid $brand-primary;
      border-radius: 50%;
      background-color: #fff;
      @include transition(border 0.15s ease-in-out);
    }

    &::after{
      display: inline-block;
      position: absolute;
      content: " ";
      width: 8px;
      height: 8px;
      left: 5px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: $input-color;
      @include scale(0, 0);

      @include transition-transform(.1s cubic-bezier(.8,-0.33,.2,1.33));
      //curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
    }
  }

  input[type="radio"]{
    opacity: 0;
    z-index: 1;

    &:focus + label::before{
      @include tab-focus();
    }

    &:checked + label::after{
      @include scale(1, 1);
    }

    &:disabled + label{
      opacity: 0.65;

      &::before{
        cursor: not-allowed;
      }
    }

  }

  &.radio-inline{
    margin-top: 0;
  }


}

@include radio-variant('.radio-primary', $brand-primary, $brand-primary);


input[type="checkbox"],
input[type="radio"] {
  &.styled:checked + label:after {
    font-family: $font-family-icon;
    content: $check-icon;
  }
  .styled:checked + label {
    &::before {
      color: red;
    }
    &::after {
      color: green;
    }
  }
}
