/* @group Collapsible (346)*/
dl.collapsible {
    border: 0.1em solid #8cacbb;
    margin: 1em 0;
    padding: 0;
}
dl.collapsible dt.collapsibleHeader {
    display: block;
    float: left;
    background: White;
    line-height: 1.2em;
    vertical-align: middle;
    font-size: 90%;
    position: relative;
    top: -0.6em;
    width: auto;
    margin: 0 0 -0.6em 1em;
    padding: 0 0.5em;
}
dl.collapsible dd.collapsibleContent {
    margin: 0;
    padding: 0 1em 0.4em;
    clear: left;
}

/* for IE the following isn't needed, that's why the css2 selector is used */
dl.collapsible dd.collapsibleContent > dl {
    margin: 0;
    padding: 0;
}

dl.expandedInlineCollapsible dt.collapsibleHeader,
dl.expandedBlockCollapsible dt.collapsibleHeader {
    padding: 0 0.5em 0 2em;
    background: White url(../../../../treeExpanded.png) no-repeat 0.5em 50%;
    cursor: pointer;
}

dl.collapsedBlockCollapsible {
    border: none;
    height: 1em;
    width: auto;
}
dl.collapsedInlineCollapsible dt.collapsibleHeader,
dl.collapsedBlockCollapsible dt.collapsibleHeader {
    float: none;
    position: relative;
    margin: 0 0 0 1em;
    padding: 0 0.5em 0 2em;
    line-height: 1.2em;
    cursor: pointer;
    background: White url(../../../../treeCollapsed.png) no-repeat 0.5em 50%;
}
dl.collapsedInlineCollapsible dd.collapsibleContent,
dl.collapsedBlockCollapsible dd.collapsibleContent {
    display: none;
}
dl.collapsedInlineCollapsible {
    border: none;
    height: 1em;
    width: auto;
    display: inline;
}
dl.collapsedInlineCollapsible dt.collapsibleHeader {
    position: static;
    float: none;
    margin: 0;
    padding: 0 0 0 2em;
    line-height: 1em;
    cursor: pointer;
    display: inline;
}

/* @end */

/* @group Invisibles (734)*/

.hiddenStructure {
  display: block;
  background: transparent;
  background-image: none; /* safari bug */
  border: none;
  height: 0.1em;
  overflow: hidden;
  padding: 0;
  margin: -0.1em 0 0 -0.1em;
  width: 1px;
}

.visualClear {
    clear: both;
}

/* @end */

/* @group Content History (878) */

#history dd {
}

div.historyRecord {
    margin-bottom: 1em;
}


div.historyTools ul {
    float: right;
    margin: 2px 1em;
}

div.historyTools ul li {
    display: inline;
    padding: 0 0.015em;
}

div.historyTools ul li a{
    border-bottom: none;
}

div.historyTools form {
    display: inline;
}

.historyAction {

}

.historyByLine {
    background: #ddd;
    padding: 0.5em;
    border-top-right-radius: 0.5em;
    border-top-left-radius: 0.5em;
    -webkit-border-top-right-radius: 0.5em;
    -webkit-border-top-left-radius: 0.5em;
    -moz-border-radius-topleft: 0.5em;
    -moz-border-radius-topright: 0.5em;
}

div.historyLinks {
    float: right;
    clear: right;
    margin: 0.5em 1em 0 0;
}


div.historyComment {
    padding: 0.5em 1em;
    border: 1px solid #ddd;
}

div.historyComment p {
    margin: 0;
    padding-top: 1px;
}

a.historyComparePrevious {
    border-top: 1px dotted #ccc;
    display: block;
    margin-top: 1.5em;
    margin-bottom: -0.5em;
    text-align: center;
}

a.historyComparePrevious span {
    background-color: white;
    position: relative;
    top: -0.75em;
    padding: 0 0.5em;
}

/* @end */


/* @group Data tables (copied from base.css) */

table.listing,
table.plain {
    border-spacing: 0;
    border-collapse: collapse;
}

table.invisible td,
table.invisible th,
table.plain td,
table.plain th,
table.listing td,
table.listing th {
    padding: 0.5em 1em;
    vertical-align: top;
}
table.plain th,
table.plain td {
    border: 1px solid #ddd;
}
table.plain th {
    text-align: left;
}

table.listing td.listingCheckbox,
table.listing th.listingCheckbox {
    text-align: center;
}

table.listing {
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
table.listing .top {
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
table.listing th {
    text-align: left;
    color: #666;
    border: 0.1em solid #e7e7e7;
    border-style: solid solid none;
    background: #ddd;
}
table.listing tr.odd th {
    border: 0.1em solid #fff;
    border-style: solid solid none none;
}
table.listing tbody th {
    font-weight: bold;
}
table.listing tbody tr {
    text-align:left;
}
table.listing tbody tr.odd {
    background: #eee;
}
table.listing tbody tr td {
    background: transparent;
}
table.listing tbody tr.odd td {
    background: #eee;
}
table.listing td {
    border-right: 1px solid #ddd;
}
.icons-on #listing-table td {
    line-height: 2em;
}
table.listing a {
    border: none;
    display: inline-block;
    border-bottom: none !important;
}

table.listing td.draggable {
    text-shadow: White 1px 1px 1px;
    background: #ddd !important;
    border-bottom: 1px solid white;
    padding: 0.4em 0.4em 0 0.5em;
}
/* Vertical table data listings */
table.vertical {
}
table.vertical th {
    text-align: right;
}
table.vertical td {
    border-top: 0.1em solid #e7e7e7;
    padding: 0.5em;
}
/* Grid table listings */
table.grid td,
table.grid th {
    border: 1px solid #e7e7e7;
    padding: 0.5em;
}

.dragindicator {
    outline: 2px solid #FFD700;
}

#foldercontents-order-column {
    padding: 0;
}

div.listingBar {
    font-size: 80%;
    margin: 1em 0;
}

div.listingBar .next {
    display: inline-block;
    float: right;
}

div.listingBar .previous {
    display: inline-block;
}

div.listingBar a {
    display: inline-block;
    padding: 0.25em 0.5em;
    border: 0.1em solid #ccc;
}

div.listingBar a:hover {
    color: White;
    background-color: #329fd7;
    border-color: #329fd7;
}

/* @end */

/* @group Overlay windows */

/* styling for overlays (popups) */

div.overlay {
    width: auto;
    height: auto;
    /* initially overlay is hidden */
    display: none;
    /* some padding to layout nested elements nicely  */
    margin: 1em;
    font-size: 80%;
}

div.overlay-iframe,
div.overlay-ajax {
    width: 60%;
    min-height: 100px;
    z-index: 99; /* overlays should be above everything */
}

div.overlay-iframe {
    height: 60%;
}

/* default close button positioned on upper-left corner */
div.overlaybg div.close,
div.overlay div.close {
    background-image: url('../icons/pb_close.png');
    position: absolute;
    left: -14px;
    top: -14px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    @include opacity(.5);

    &:hover {
      @include opacity(1);
    }
}

.pb-ajax {
    overflow-y: auto;
}

.pb-ajax, .pb-image, .overlay iframe {
    background-color: #fff;
    border: 1px solid #999;
    white-space: normal;
    box-shadow: 0 0 3em 0.5em #666;
    -moz-box-shadow: 0 0 3em 0.5em #666;
    -webkit-box-shadow: 0 0 3em #666;
}

.pb-ajax > div {
    width: 92%;
    padding: 1em;
}

.pb-ajax .documentActions {display:none}

div.overlay div.close span {
    display: block;
    height: 1px;
    margin: -1px 0 0 -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
}

div.overlay textarea { width: 100%; }

/* Hide form elements that don't make sense within an overlay. */
div.overlay .link-parent { display: none; }
div.overlay #createGroup legend { display: none; }
div.overlay #createGroup fieldset { border: none; }

/* lets play nice with paragraphs, links, tabbed pages in overlay e.g. when plone.openid is installed */
div.overlay p {
    margin-bottom: 1em;
}
div.overlay label {
    margin-bottom: 0.25em;
}
div.overlay a:hover label{
    cursor: pointer;
}
div.overlay a.selected {
    color: #000000;
}
div.overlay .formTab {
    margin-right:1em;
    font-weight: bold;
}
div.overlay .formTab.lastFormTab {
    margin-right:0em;
}
div.overlay .formControls a {
    display: block;
    margin: 0.75em;
}

/* @end */

.icons-on {
  .contenttype-document {
    &::before {
      font-family: fontawesome;
      content: "\f0f6"; // fa-file-text-o
      padding-right: 0.5em;
    }
  }
  .contenttype-file {
    &::before {
      font-family: fontawesome;
      content: "\f019"; // fa-download
      padding-right: 0.5em;
    }
  }
  .contenttype-event {
    &::before {
      font-family: fontawesome;
      content: "\f073"; // fa-calendar
      padding-right: 0.5em;
    }
  }
  .contenttype-folder {
    &::before {
      font-family: fontawesome;
      content: "\f114"; // fa-folder-o
      padding-right: 0.5em;
    }
  }
  .contenttype-link {
    &::before {
      font-family: fontawesome;
      content: "\f0c1"; // fa-external-link
      padding-right: 0.5em;
    }
  }
  .contenttype-news, 
  .contenttype-news-item {
    &::before {
      font-family: fontawesome;
      content: "\f1ea"; // fa-newspaper-o
      padding-right: 0.5em;
    }
  }
  .contenttype-topic {
    &::before {
      font-family: fontawesome;
      content: "\f0c5"; // fa-files-o
      padding-right: 0.5em;
    }
  }
  .contenttype-formfolder {
    &::before {
      font-family: fontawesome;
      content: "\f03a"; // fa-list
      padding-right: 0.5em;
    }
  }
}

/* @group Album view classes */

.photoAlbumEntryWrapper {
    display: block;
    width: 128px;
    margin-bottom: 5px;
    overflow: hidden;
}

.photoAlbumEntry {
    float: left;
    position:relative;
    overflow: hidden;
    height: 185px;
    width: 157px;
    margin: 0.5em;
    padding: 0px 6px 0px 9px;
    text-align: center;
    background-image: url('/++theme++ugent/static/images/polaroid-single.png');
    background-repeat: no-repeat;

    img {
        border: 1px solid #ccc;
        display: block;
        margin: 0 auto;
    }

    a {
        display: block;
        text-decoration: none;
        font-size: 90%;
        height: 169px;
        width: 130px;
        margin: 16px auto 0px;
        border-bottom: none
    }
}

.photoAlbumFolder {
    background-image: url('/++theme++ugent/static/images/polaroid-multi.png');
    background-repeat: no-repeat;
}

.photoAlbumEntryTitle {
    color: #76797c;
    display: block;
    width: 128px;
    height: 3.6em;
}

/* @end */

/* @group Status messages */

dl.portalMessage,
dl.iterateMessage,
dl.lockMessage,
div.attention,
div.caution,
div.danger,
div.error,
div.hint,
div.important,
div.note,
div.tip,
div.warning {
    margin: 1em 0;
    background-color: #ffffe3;
    clear:both;
}
div.error > .error {
    padding: .5em;
    margin: 0;
}
dl.portalMessage a,
dl.iterateMessage a,
dl.lockMessage a,
div.attention a,
div.caution a,
div.danger a,
div.error a,
div.hint a,
div.important a,
div.note a,
div.tip a,
div.warning a,
div.attention a,
div.caution a,
div.danger a,
div.error a,
div.hint a,
div.important a,
div.note a,
div.tip a,
div.warning a {
    color: black;
    border-bottom: 1px solid #888;
}
dl.portalMessage dt,
dl.iterateMessage dt,
dl.lockMessage dt,
div > .admonition-title {
    background-color: #996;
    font-weight: bold;
    float: left;
    margin: 0 0.5em 0 0;
    padding: 0.5em 0.75em;
    color: White;
    line-height: 1.25em;
}
dl.portalMessage dd,
dl.iterateMessage dd,
dl.lockMessage dd,
div.attention > .last,
div.caution > .last,
div.danger > .last,
div.error > .last,
div.hint > .last,
div.important > .last,
div.note > .last,
div.tip > .last,
div.warning > .last {
    padding: 0.5em 0.5em;
    margin: 0;
    line-height: 1.25em;
}

dl.warning {
    border-color: #d80;
}
dl.warning > dt,
.warning > .admonition-title {
    background-color: #d80;
}

dl.error {
    border-color: red;
}
dl.error > dt,
.error > .admonition-title {
    background-color: #d00;
}
.warning > dd,
.warning > .last {
    background-color: #fd7;
}
.error > dd,
.error > .last {
    background-color: #fdc;
}

/* Additional specificity for when status is shown inside #content */
dl.portalMessage,
dl.iterateMessage,
dl.lockMessage,
div.attention a,
div.caution a,
div.danger a,
div.error a,
div.hint a,
div.important a,
div.note a,
div.tip a,
div.warning a {
    font-size: 100%;
}
dl.portalMessage dt,
dl.iterateMessage dt,
dl.lockMessage dt,
div > .admonition-title {
    margin: 0 0.5em 0 0;
    padding: 0.5em 0.75em;
}
dl.portalMessage dd,
dl.iterateMessage dd,
dl.lockMessage dd,
div.attention > .last,
div.caution > .last,
div.danger > .last,
div.error > .last,
div.hint > .last,
div.important > .last,
div.note > .last,
div.tip > .last,
div.warning > .last {
    margin: 0;
}

/* @end */

/* @group Image alignment classes */

.image-left {
   float: left;
   margin: 0.5em 1em 0.5em 0;
}
.image-right {
   float: right;
   margin: 0.5em;
}
.image-inline {
   float: none;
}

/* @end */

/* File icons */

span.pdf, span.external, span.doc, span.ppt, span.xls, span.audio, span.video, span.beveiligd, span.anchor {
    &::after {
        font-family: FontAwesome;
        text-decoration: none;
        margin-left: 10px;
    }
}

span.pdf::after {
    // fa-file-pdf-o
    content: "\f1c1";
}
span.external::after {
    // fa-external-link
    content: "\f08e";
}
span.doc::after {
    // fa-file-word-o
    content: "\f1c2";
}
span.ppt::after {
    // fa-file-powerpoint-o
    content: "\f1c4";
}
span.xls::after {
    // fa-file-excel-o
    content: "\f1c3";
}
span.audio::after {
    // fa-file-audio-o
    content: "\f1c7";
}
span.video::after {
    // fa-file-video-o
    content: "\f1c8";
}
span.beveiligd::after {
    // fa-lock
    content: "\f023";
}
span.anchor::after {
    // fa-arrow-down
    content: "\f063";
}

/* <div class="pullquote">
 * PCM-539: style as bootstrap alert alert-warning
 * do not forget Fabrique bootstrap_overwrites
 */

.pullquote {
  padding: $alert-padding;
  margin-bottom: $line-height-computed;
  border: 1px solid transparent;
  border-radius: $alert-border-radius;

  // Headings for larger alerts
  h4 {
    margin-top: 0;
    // Specified for the h4 to prevent conflicts of changing $headings-color
    color: inherit;
  }

  // Provide class for links that match alerts
  .alert-link {
    font-weight: $alert-link-font-weight;
  }

  // Improve alignment and spacing of inner content
  > p,
  > ul {
    margin-bottom: 0;
  }

  > p + p {
    margin-top: 5px;
  }

  @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);

  /* bootstrap_overwrites */
  padding: $padding-default;
  border: 0;
  color: $black;
  *:first-child {
    margin-top: 0;
  }
}


blockquote.newstyle {
  padding-left: 55px;
}
blockquote.newstyle::before {
  display: block;
  margin-left: -36px;
  content: '\f10d';
  font-family: fontawesome;
  font-weight: 900;
  margin-right: 15px;
  font-size: 24px;
  float: left;
  margin-top: -4px;
  color: $brand-primary;
}

blockquote.pullquote.newstyle::before {
  content: '\f06a';
}

/* color for workflow state internal in referencebrowserwidget */
#referencebrowserwidget .state-internal {
  color: #f0ad4e;
}

/* workflow state colors */
.state-private {
  color: #c4183c;
}
.state-visible {
  color: #74AE0B;
}
.state-internal {
  color: #fab82a;
}

// horizontal line above viewlet below content (PCM-645)
.portaltype-news-item,
.portaltype-event {
  #viewlet-below-content {
    border-top: 1px solid $border-color;
    // padding and margin are normally applied to the bottom of divs, so they need
    // to be reversed here
    margin-top: $padding-large-mobile;
    @media(min-width: $screen-sm-min) {
      margin-top: $padding-large;
    }
    padding-top: $margin-default-vertical-mobile;
    @media(min-width: $screen-sm-min) {
      padding-top: $margin-default-vertical;
    }
  }
}

// add additional margin above viewlets below content if there is another div above this div (PCM-645)
#viewlet-below-content > div + div {
  padding-top: $padding-default;
}

@media(max-width: $screen-sm-max) {
  #viewlet-below-content .nextprevious {
    padding-bottom: $padding-large-mobile;
  }
}

// make sure social like viewlet has same distance below as other viewlets
#viewlet-below-content #viewlet-social-like {
  min-height: 1.3em;
}

// style listing_view (collections) (PCM-1938)
.portaltype-collection.template-listing_view {

  .entry {

    margin-left: 5px;

    .documentByLine {
      display: none;
    }

    .description {
      display: none;
    }

    .mime-icon {
      display: none;
    }

    a:first-child::before {
      content:'→';
      font-family: "PannoTextMedium",Arial,sans-serif;
      font-size: 16px;
      color: black;
      display: inline-block;
      padding-right: 0.5em;
    }
    a:nth-child(2):before {
      content: none;
    }

    .thumb-thumb {
      display: none;
    }
  }

  header {
    margin-bottom: 10px;

    a {
      border-bottom: 1px solid transparent;
    }

  }

}

// remove border from iframes (PCM-1945)
iframe {
  border: none;
}



