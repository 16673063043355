.image-panel {
  position: relative;

  .image {
    max-height: 500px;
    display: block;
    margin: 0 auto;
    padding: 30px;
  }

}
