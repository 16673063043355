$cardBorderColor: lighten($brand-primary, 45%);

.cards {
    margin: 0 -1rem; // row'ish
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .card-wrapper {
        display: inline-flex;
        position: relative;
        width: 100%;  // 1 col
        @media(min-width: $screen-sm-min) {
            width: 50%;   // 2 cols
        }
        @media(min-width: $screen-lg-min) {
            width: 33.3%; // 3 cols
        }
        padding: 1rem;
        box-sizing: border-box;
    }

    .card {
        width: 100%;
        border: 1px solid $cardBorderColor !important;
        &:hover {
            border: 1px solid $brand-primary !important;
        }
        @extend .bg-tertiary;
        display: flex;
        flex-direction: column;
        text-decoration: none !important;
        .card-title {
            padding: 0 10px;
            margin: 10px 0;
            font-weight: 900;
        }
        .card-summary {
            padding: 0 10px 10px 10px;
            // margin: 10px 0;
            color: $dark-text-color;
        }
        .card-fill {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
        }
    }
    .card-image {
        background-color: #fff;
        img {
            width: 100%;
        }
    }
}
