//
// Popovers
// --------------------------------------------------


.popover {
  border-radius: 0;
}

.popover-title {
  border-radius: 0;
  color: $ugent-popover-title-color;
}

