.twitter-typeahead {
  width: 100%;
  float: left;
}
.tt-menu {
  background-color: $white;
  width: 100%;
  padding: 0 10px 4px;

  .tt-suggestion {
    margin: 5px 0;
  }
}
.tt-open {
  border: 2px solid $border-color;
  border-top: 0;
}
.tt-hint {
  color: $gray-light;
}
