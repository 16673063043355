.example-layout {
  .row {
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid $brand-tertiary;
  }
  div[class*='col-'] {
    height: 180px;
    margin-bottom: 20px;
    >div {
      width: 100%;
      height: 100%;
      border: 1px solid $brand-primary;
      color: $brand-primary;
      position: relative;
      text-align: center;
      line-height: 120px;
    }
  }
}
