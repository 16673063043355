#bttBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    border: none;
    outline: none;
    color: $brand-primary;
    cursor: pointer;
    padding: 10px;
    border-radius: 2px;
    font-size: 18px;
    @extend .bg-tertiary;
    svg {
      margin-bottom: 2px;
    }
  }
  
#bttBtn:hover {
    @extend .bg-primary;
}
