// Overriding plone.staticresources-1.4.3-py3.7.egg/plone/staticresources/static/components/mockup/mockup/less/ui.less

/* loading icon animation */
.plone-loader{
  z-index: 999;
  display:none;
  position: fixed;
  top: 50%;
  left: calc(50% - 2.5em);

  div {
    margin: -6em auto;
    font-size: 5px;
    text-indent: -9999em;
    border-top: 2.5em solid rgba(255, 210, 0, 0.7);
    border-right: 2.5em solid rgba(255, 210, 0, 0.15);
    border-bottom: 2.5em solid rgba(255, 210, 0, 0.15);
    border-left: 2.5em solid rgba(255, 210, 0, 0.15);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  div,
  div:after {
    border-radius: 50%;
    width: 15em;
    height: 15em;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
                                                                               

