.linklist {
  li {

    &:last-child {
      margin-bottom: -3px;
    }
    .content-header-subhome & {
      color: $white;
    }
    a {
      border-bottom: 1px solid transparent;
      &:hover,
      &:focus,
      &.selected {
        color: $brand-primary;
        border-color: $brand-primary;
      }
    }
    ul {
      margin-top: 8px;
      padding-left: 10px;
    }
  }

  &.linklist-inverted,
  .content-header-subhome & {
    color: $white;
    padding-bottom: 11px;
    a {
      &:hover,
      &:focus,
      &.selected {
        color: $white;
      }
    }
  }

  &.linklist-dark {

    a,
    &:before {
      color: $black;
    }
    a {
      &:hover,
      &:focus,
      &.selected{
        color: $brand-primary;
      }
    }


  }

  .bg-tertiary & {
     li {
       &:before {
         color: $brand-primary;
       }
     }
  }
}
