.thumbnail-container {
  .beeldbank {
    position: relative;
    padding-bottom: 66.67%;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

// Not related to above thumbnail.
// Put here because related to thumbnail folder/collection view.

.referenced-image {
  display: table;
  margin-bottom: 1em;
  @media(min-width: 480px) {
    float: right !important;
    margin-left: 2em;
    margin-bottom: 1em;  
  }
  &>p {
    display: table-caption;
    caption-side: bottom;
  }
}
