.inpage-nav-container {

  position: sticky;
  top: 10px;
  margin-bottom: 10px;

  .h2 {
    margin-top: 0;
  }
  
  nav {
    
    .linklist {
      a {
        &:focus {
          border-bottom: transparent;
          color: $default-text-color;
        }
        &.nav-active {
          border-bottom: 1px solid $brand-primary;
          color: $brand-primary;
        }
      }
    }

  }

  .tocify-focus {
    a {
      border-bottom: 1px solid $brand-primary;
      color: $brand-primary;
    }
  }
}
