// Following two lines conflict with the Plone CSS:
// https://github.ugent.be/UGent-Huisstijl/websites/blob/1.4.2/sass/partials/_generic-elements.scss#L17-L18
// Result is the Plone menu is broken. Below unsets fix this.
body.frontend #edit-zone li {
    margin-bottom: unset;
    position: unset;
}
// It would be better if the Plone CSS was more bullet proof so it cannot be modified by our theme.
// It also would not hurt if our theme itself was namespaced...


.icon-logout {
    border-bottom: none !important;
}
.icon-logout::before {
    font-family: fontawesome;
    content: "\f08b"; // fa-sign-out
    padding-right: 0;
    margin-right: 0;
}
.nav-tertiary a[href*="/login"]::after {
    font-family: fontawesome;
    content: "\f090"; // fa-sign-in
    padding-left: 0.5em;
}
.nav-tertiary #siteaction-home_ugent {
    display: none;
}

.linklist-events, .table-newsoverview, .faceted-results {
    .date {
        border: none;
    }
}

body.si-hidden .si-toggle-container {
    display: none;
}

.icons-on .relatedItems .url::before {
    content: none;
}

#viewlet-above-content .alert:first-of-type {
    margin-top: 4px; /* like aside next to it */
}

#edit-zone a#portlet-manager-all {
    display: none;
}

.named-file-widget.form-control {
    border: none;
    box-shadow: none;
}

.frontend .easyformForm .pattern-pickadate-now {
    display: none;
}

.frontend .easyform-thankspage > .discreet {
    display: none;
}

// logged-in.css centers .date
.frontend .date {
    text-align: inherit;
}
.linklist-events .date {
    padding: 0;
}

// #viewlet-below-content has no border and little top spacing itself
// the blocks under it, like "more link this" and "share on social media"
// each have there own
#viewlet-below-content {
	border-top: 0 !important;
    padding-top: 0 !important;
    margin-top: 45px !important;
	&>div {
        border-top: 1px solid #ccc;
        margin-top: 15px;
        padding-top: 15px;
	}
}

.easyform-thankspage span.form-control {
    height: auto;
}

// Match the margin on the img
figure.pull-right figcaption {
    margin: .5em;
}

.frontend {
    .pat-pickadate + .invalid-feedback, .pattern-pickadate-wrapper .invalid-feedback {
        display: none;
    }
}

.template-full_view #content .item {
    margin: 3em 0 6em 0;
}

#center-content-area #faceted-results > div > .row:first-child {
    margin-top: 60px;
}

#content-core h3 {
    margin-top: 25px !important;
}

#content-core .btn.btn-primary {
    margin: 1em 0;
}
// tags in search results seem to be primary buttons...
#content-core #search-results .btn.btn-primary.btn-tag {
    margin: 0;
}

@media only screen and (max-width: $screen-sm-min), only screen and (max-width: $screen-md-min) and (orientation: landscape) {
    // Unleash some CSS on breadcrumbs on mobile (PCM-2160)
    ol#breadcrumbs li {
        display: none;
    }
    ol#breadcrumbs li:nth-last-child(2) {
        display: inline-block;
        &::before {
            content: '< to ';
            color: black;
        }
        &:lang(nl)::before {
            content: '< naar ';
        }
        &::after {
            content: none;
        }
    }

    // More tweaks for mobile('ish) (PCM-2174)
    // 1. make H1 smaller (normal pages)
    #content {
        h1, .h1 {
            font-size: 35px;
        }
    }
    // 2. No image on faq/target homepages.
    .content-header-subhome .image-container {
        display: none !important;
    }
    .content-header-subhome > .col-ms-6 {
        width: 100% !important;
    }
    // 3. Show toc under H1 -> JS
}


// Hack to undo a hack with side effects but without references to whatever it is hacking.
// See "ipad hack" in _generic-elements.scss in the websites repo.
// ¯\_(ツ)_/¯
@media(min-width: $screen-sm-min){
    .flexbox {
        .col-lg-3:last-child {
            width: 25% !important;
        }
    }
}

body.subsite-type-faculty header .branding-container {
    @media(min-width: $screen-sm-min){
        a { color: white; }
    }
    .replaced-svg {
        width: auto;
        height: 50px;
        max-width: 100%;
        margin-top: -7px;
    }
}

.pageheader .navbar-default .nav-primary > li {
  &>a, &>a:hover, &>a:focus {
    span {
        display: inline;
    }
  }
  &.active > a, &:hover > a {
      text-decoration: underline !important;
      text-decoration-thickness: 0.1em;
      text-underline-offset: 0.25em;
  }
}

#facetted-search {
    margin-top: 12px;
    @extend .bg-tertiary;
    padding: 20px;
    .optionsToggle label {
        background-color: transparent;
    }
    label {
        font-family: "PannoTextLight",Arial,sans-serif;
        font-weight: 300;
    }
}
.template-search .result-header {
    padding: 0;
}


#bttBtn > div {
    &.english-only { display: none; }
    &:lang(en) {
        &.dutch-only { display: none; }
        &.english-only { display: block; }
    }
}

#searchform #facetted-search a {
    margin-bottom: 6px;
    display: inline-block;
}
#searchResultsSort #sorting-options a::first-letter {
    text-transform: uppercase;
}


body div.ppms_cm_popup_overlay.mobile-lg div.ppms_agree_button_wrapper,
body div.ppms_cm_popup_overlay.mobile-lg div.ppms_agree_button_wrapper:focus,
body div.ppms_cm_popup_overlay.mobile-lg div.ppms_agree_button_wrapper:hover,
body div.ppms_cm_popup_overlay.mobile-sm div.ppms_agree_button_wrapper,
body div.ppms_cm_popup_overlay.mobile-sm div.ppms_agree_button_wrapper:focus,
body div.ppms_cm_popup_overlay.mobile-sm div.ppms_agree_button_wrapper:hover {
    bottom: 0 !important;
}

body div.ppms_cm_popup_overlay.mobile-lg button.ppms_cm_save-choices,
body div.ppms_cm_popup_overlay.mobile-lg button.ppms_cm_save-choices:focus,
body div.ppms_cm_popup_overlay.mobile-lg button.ppms_cm_save-choices:hover,
body div.ppms_cm_popup_overlay.mobile-sm button.ppms_cm_save-choices,
body div.ppms_cm_popup_overlay.mobile-sm button.ppms_cm_save-choices:focus,
body div.ppms_cm_popup_overlay.mobile-sm button.ppms_cm_save-choices:hover {
    bottom: 17px !important;
}
