// Remove whitespace above filter results
.faceted-results {
  margin-top: -2em;
  @media(min-width: $screen-sm-min) {
    margin-top: -3.55em;
  }
}

// Provide some padding above and below filters
.widget-fieldset {
  padding: .35em 0 .75em;
}

// Display filters as disabled while loading results
.faceted-widget-locked {
  label {
    color: $border-color;
  }

  .checkbox {
    label {
      &::before {
        border-color: $border-color;
      }
    }
  }
}

.frontend .faceted-widget .checkbox :before {
  outline: none !important;
}

// Lighten checkbox styling
.faceted-widgets, #facetted-search {
  legend {
    border: 0;
    font-size: 19px;
    font-family: "PannoTextMedium",Arial,sans-serif;
    margin-bottom: 0.3em;
    margin-top: 1.3em;
    font-weight: unset !important;
  }
  .checkbox {
    label {
      font-family: $light-font-family;

      &::before {
        border-width: 1px;
        height: 15px;
        left: 5px;
        top: 1px;
        width: 15px;
      }

      &::after {
        font-size: 10px;
        left: 4px;
        top: 1px;
      }
    }
  }
}

/** Fixes for eea.facetednavigation on actueel/agenda (PCM-1104, PCM-927, PCM-1219) */

// Remove category actueel from widget
.section-nl-actueel.template-facetednavigation_view .faceted-widget li label span,
.section-en-news-events.template-facetednavigation_view .faceted-widget li label span,
#c0_option_c0_persbericht,
#c0_option_c0_actueel,
#c1_option_c1_actueel,
#c2_option_c2_actueel,
#c3_option_c3_actueel,
#c4_option_c4_actueel,
#c5_option_c5_actueel {
  display: none;
}

// Remove widget title
.section-nl-actueel,
.section-ge-nl-actueel,
.section-en-news-events {
  .faceted-widget {
    .widget-fieldset {
      h3 {
        display: none;
      }
    }
  }
}

.template-facetednavigation_view {
  .faceted_loading {
    background: none;
    @extend .plone-loader;
    display: block !important;
  }
  #parent-fieldname-text {
    margin-bottom: 2em;
  }  
}

/* Remove border-bottom & padding from last item in faceted results (PCM-1219) */ 
#faceted-results {
  div.row.border-bottom:nth-last-of-type(1) {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.faceted-date-widget-form .ui-widget-content {
  .ui-state-default, .ui-widget-header {
    background-color: $brand-primary;
  }
  .ui-slider-range {
    opacity: .5;
  }
  .ui-slider-handle {
    margin-left: -0.6em;
    outline: none !important;
  }
  .ui-slider-label {
    font-size: 14px;
  }
  .ui-slider-label.ui-slider-label-show {
    display: none !important;
  }

  .ui-slider {
    border-color: $brand-primary;
  }
}
