.page-footer {
  .responsible {
    font-size: $fz-small;
    a {
      text-decoration: none;
      border-bottom: 1px solid transparent;
      &:hover,
      &:focus {
        color: $white;
        border-color: $white;
      }
    }
  }
}

// PCM-1004
#pref_partners {
  img {
    height: 50px;
  }
  padding-right: 0;
  width: 74%; // to fix issue in Safari

  ul {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-right: 0;

    li {
      float: left;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    li:last-child {
      margin-right: 0;
    }
    // hide arrow on list items
    li:before {
      content: "";
    }
  }
}

// only show when there is enough room
@media(max-width: $screen-sm-min) {
  #pref_partners {
    display: none;
  }
}
