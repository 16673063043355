/* LiveSearch styles */
/* overgenomen uit skins/_styles/public.css.dtml */
/* kan nog veel aan verbeterd worden */


$globalBackgroundColor: #dee7ec;
$globalFontColor: #436976;
$backgroundColor: white;
$borderWidth: 1px;
$borderStyle: solid;
$globalBorderColor: #8cacbb;
$discreetColor: #76797c;
$fontSmallSize: 90%;

.LSRes {
    visibility: visible;
    color: $globalFontColor;
    background-color: $backgroundColor;
    vertical-align: middle;
    display:block;
    list-style-image: none;
    list-style-type: none;
    text-align: left;
    min-width: 16.5em;
    text-transform: none;
    margin-left: 0;
    line-height: 1.1em;
}
#LSHighlight,
.LSHighlight {
    background-color: $globalBackgroundColor;
    border: $borderWidth $borderStyle $globalBorderColor;
    color: $globalFontColor;
}
.LSTable {
    margin: 1em 0;
}

.LSDescr {
    color: $discreetColor;
    font-size: $fontSmallSize;
    font-weight: normal;
    margin-left: 1.5em;
}
.LSResult {
    position: absolute;
    left: -1px;
    right: -3px;
    display: block;
    text-align: right;
    padding-top: 5px;
    margin: 0;
    top:25px;
    z-index: 3;
}
.LSShadow {
    position: relative;
    text-align: right;
}
.livesearchContainer {
    line-height: 1.5em;
    background-color: $backgroundColor;
    margin-top: 0;
    padding: 0 !important;
    position: absolute;
    right: 2px;
    /* Currently exposes a rendering bug in Mozilla */
    top: 0;
    white-space: normal;
    visibility: visible;
    text-align: left;
    color: $globalFontColor;
    border: $borderWidth $borderStyle $globalBorderColor;
    text-transform: none;

    .LSRow {
        border: $borderWidth $borderStyle $backgroundColor;
        white-space: normal;
        padding:0;
        margin: 0;
        list-style-image: none;
        list-style-type: none;
    }

    .LSRow a {
        line-height: 1.5em;
        text-decoration: none;
        font-weight:bold;
        white-space:nowrap;
        border-bottom: none;
    }
}
* html .livesearchContainer {
    padding: $borderWidth !important;
    padding-top: 0 !important;
    background-color: $globalBorderColor;
    border: 0;
}


/* Workaround for Internet Explorer's broken z-index implementation */
.LSIEFix {
    background-color: $backgroundColor;
    padding: 0.5em !important;
    z-index: 20;
}
.LSBox {
    clear: left;
    float: left;
    text-align: right;
    padding-right: 1px;
    display:block;
}
#LSNothingFound {
    text-align: center;
    padding: 2px;
}
.LSBox label {
    font-weight: normal;
}

/* Responsive design: definitions for large screens */
@media(min-width: $screen-sm-min) {
  .LSResult {
    position: relative;
    left: 3px;
    right: auto;
  }
  .livesearchContainer {
    width: 35em;
  }
}

// Linked images have a z-index of 10 (PCM-848)
.pageheader {
  .row.search {
    .search-container {
      .input-group {
        z-index: 20;
      }
    }
  }
}
