.news-aside,
.events-aside {

  ul {
    margin-bottom: 0;
  }
  li {
    &:last-child {
      margin-bottom: 0;
    }
  }

  a,
  a>*{
    text-decoration: none;
  }

  .readmore {
    font-family: $medium-font-family;
    position: relative;
    padding-left: 18px;
    display: inline-block;
    i {
      position: absolute;
      left: 0;
      top: 3px;
    }
  }
  .linklist-news {
    span {
      float: none;
    }
  }

  h3 {
    font-family: $light-font-family;
    color: $black;
    margin-top: 5px;
    margin-bottom: 0;
    line-height: 20px;
  }
  .has-image {
    h3 {
      font-family: $default-font-family;
      color: $brand-primary;
      margin-bottom: 25px;
    }
    img {
      margin-bottom: 10px;
    }
  }

}


