.image-border {
  margin-bottom: $margin-default-vertical-small;

  img {
    border: 1px solid $border-color;
    padding: 10px;
  }

  a {
    border-bottom: 0;
  }
}