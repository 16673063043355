
// explicit style for 'template' sites
.section-vakgroepsite {
	.content-header-subhome *, .linklist li {
		color: $brand-primary;
	}
}

// PCM-1183

.content-header-subhome {
	.favorites-list {
		@media(min-width: $screen-sm-min) {
			ul {
       			 margin-bottom: 0;
      		}

			li {
				width: 100%;
	      	}
		}
	}
}
