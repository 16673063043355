@font-face {
  font-family: 'PannoTextNormal';
  src: url('../fonts/panno/ugentpannotext-normal-web.eot');
  src: url('../fonts/panno/ugentpannotext-normal-web.eot') format('embedded-opentype'),
  url('../fonts/panno/ugentpannotext-normal-web.woff') format('woff'),
  url('../fonts/panno/ugentpannotext-normal-web.ttf') format('truetype');
}

@font-face {
  font-family: 'PannoTextSemiBold';
  src: url('../fonts/panno/ugentpannotext-semibold-web.eot');
  src: url('../fonts/panno/ugentpannotext-semibold-web.eot') format('embedded-opentype'),
  url('../fonts/panno/ugentpannotext-semibold-web.woff') format('woff'),
  url('../fonts/panno/ugentpannotext-semibold-web.ttf') format('truetype');
}

@font-face {
  font-family: 'PannoTextMedium';
  src: url('../fonts/panno/ugentpannotext-medium-web.eot');
  src: url('../fonts/panno/ugentpannotext-medium-web.eot') format('embedded-opentype'),
  url('../fonts/panno/ugentpannotext-medium-web.woff') format('woff'),
  url('../fonts/panno/ugentpannotext-medium-web.ttf') format('truetype');
}

@font-face {
  font-family: 'PannoTextLight';
  src: url('../fonts/panno/ugentpannotext-semilight-web.eot');
  src: url('../fonts/panno/ugentpannotext-semilight-web.eot') format('embedded-opentype'),
  url('../fonts/panno/ugentpannotext-semilight-web.woff') format('woff'),
  url('../fonts/panno/ugentpannotext-semilight-web.ttf') format('truetype');
}
