/* Fix unordered list styling on newsitems (PCM-592) */

.template-newsitem_view {
	#content {
		ul {
			padding-left: 0;
		}
		ul li:before {
				position: relative;
				left: 0;
		}
		/* Fix styling of nested unordered lists (PCM-1128) */
		ul ul {
			padding-left: 10px;
		}
	}
}

.easyformForm {
	legend {
		color: #1E64C8;
		font-family: "PannoTextMedium", Arial, sans-serif;
		font-weight: 500;
		text-transform: none;
		font-size: 20px;
		line-height: 20px;
		border-top: 1px solid #ccc;
		border-bottom: 0;
		margin-bottom: 10px;
		padding-top: 30px;
	}
}

/* Fix styling for focus items (PCM-1111) */

/* fix for black divider on hover on non-faculty sites */
a.block-link:hover div span.month {
	border-bottom: 2px solid #FFD200;
}

#homepage-focus {
	span.date {
		padding: 0;
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 1px solid white;
	}


	&.agendaitem {
		a {
			text-decoration: none;
			transition: border-color .15s ease-in-out;
		}

		.h3 {
			font-family: "PannoTextMedium", Arial, sans-serif;
			font-weight: 500;
			color: #1E64C8;

			span {
				border-bottom: 1px solid transparent;

				&:hover,
				&:focus {
				border-color: #1E64C8;
				}
			}
		}

		div {
			color: $default-text-color;
		}
	}
}

/* Fix for missing underline on links in h3's (PCM-1112)  */
#content-core {
	h3 {
		a {
			border-bottom: 1px solid $brand-primary;
		}
	}
}

/* Remove border-bottom from prev/next button in ekko-lightbox gallery (PCM-1141) */
.ekko-lightbox-nav-overlay {
	a {
		border-bottom: 0;
	}
}
