.frontend .easyformForm {
    .form-group.error {
        background-color: transparent;  // undo plone styling
        @extend .has-error;             // pull in websites styling
    }
    .fieldErrorBox .error {
        display: inline-block;          // undo plone styling
        background-color: transparent;  // undo plone styling
        margin: 0;                      // undo plone styling
    }
    #form-widgets-doelgroep .option {
        @extend .checkbox;              // pull in websites styling
        @extend .checkbox-primary;      // pull in websites styling
        margin-bottom: 8px;             // undo (plone) styling
        label { padding-left: 0; }      // undo (plone) styling
    }
    
    .submit-widget.button-field {
        @extend .btn;                   // pull in websites styling
        @extend .btn-default;           // pull in websites styling
    }

    // show fieldset titles that are hidden by default
    legend {
        display: block !important;
    }

    // except for the last one that holds fields that were on the
    // PFG form root.
    #fieldsetlegend-default-end, #fieldsetlegend-default {
        display: none !important;
        &+p { display: none !important; }
    }

    // selects were not full width, so shrinking them
    .bootstrap-select {
        max-width: 470px;
        display: block;
    }

    fieldset:first-of-type legend {
        border-top: none;
    }

    .form-group .option {
        display: block;
        .radio-widget.form-control, .checkbox-widget.form-control {
            width: unset;
            height: unset;
            display: inline-block;
        }
    }
    .form-group > .option:first-of-type {
        margin-top: .5em;
    }
}

