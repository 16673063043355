.pageheader {
  .navbar-default .navbar-nav.nav-quaternary {
    #ugent-membertools .dropdown-toggle {
      &::after {
        content     : '↓';
        padding-left: .5em;
      }

      &:hover {
        text-decoration: none;
      }

      font-weight: 700;
      padding-left: 20px;
    }

    &>li>a {

      &,
      &:hover,
      &:focus {
        color: white !important;
      }
    }

    &>li.open>a {

      &,
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }

  .page-logo {
    img {
      &.th-nl {
        width: 113px;
      }
      &.th-en {
        width: 108px;
      }
      &.gc-en {
        width: 159px;
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    .page-logo {
      img {
        &.th-nl {
          width: 120px;
        }
        &.th-en {
          width: 114px;
        }
        &.gc-en {
          width: 169px;
        }
      }
    }
  }

  h1,
  .h1 {
    @media(min-width: $screen-sm-min) {
      font-size: $fz-mediumplus;
    }
    @media(min-width: $screen-lg-min) {
      font-size: $fz-large;
      white-space: nowrap;
      visibility: hidden;
    }
  }

  .branding-container {
    @media(min-width: $screen-sm-min) {
      top: 48px;
    }
  }
}
