.content-text-image {
  h2 {
    margin-top: 0;
    margin-bottom: 10px;

  }
  p {
    max-width: none;
  }

  .pull-left + .no-float {
    .linklist {
      float: right;
    }
  }
}
