.result-item {
  padding: 0 0 25px;
  margin: 0 0 25px;

  border-bottom: 1px solid $border-color;

  > * {
    max-width: $maxwidth-content;
  }
  h2 {
    //display: inline-block;
    margin-top: 0;
    margin-bottom: 8px;
    font-size: $fz-mediumplus;
    a {
      text-decoration: none;
      border-bottom: 1px solid transparent;
      &:hover,
      &:focus {
        border-color: $brand-primary;
      }
    }
  }
  .breadcrumb {
      margin-bottom: 0;
    * {
      color: $black;
      font-size: $fz-small;
    }
    li {
      margin-bottom: 10px;
      + li {
        &:before {
          color: $black;
        }
      }
    }
    a {
      text-decoration: none;
      border-bottom: 1px solid $default-text-color;
      &:hover,
      &:focus {
        border-color: transparent;
      }
    }
  }

  .list-inline {
    li {
      padding-right: 0;
      margin-bottom: 0;
    }
  }

}
