.mce-content-body {
  .collapse {
    display: block;
    background-color: lighten($brand-secondary, 40%);
  }

  h2 {
  	margin-top: 35px;
  }
}
