/* override Plone (Sunburst Theme) css  */


/* Reset margin on .spacer div, coming from base.css */

div.bg-primary.spacer {
  margin: 0;
}

/* Reset edit bar style */

.contentViews,
.contentActions {
  // fix line-height
  line-height: 26px;
  // li items are normally styled in reset.css
  li {
    display: inline;
  }
}

.contentActions {
  // fix contentActions dl margin-bottom, coming from bootstrap type
  dl {
    margin-bottom: 0px;
  }
  // fix contentActions dt, dd line-height, coming from bootstrap type
  dt,
  dd {
    line-height: 26px;
  }
  // fix contentActions a border-bottom, coming from fabrique table
  a {
    border: none;
  }
  // fix contentActions left padding
  ul {
    padding: 0;
    // fix sub-types and acties width problem
    ul a {
      padding-right: 20px;
    }
  }
}

#contentActionMenus {
  // remove rounded corners, coming from authoring.css
  border-radius: 0;
  // increase z-index to show above slideshow portlet (PCM-823)
  z-index: 11;
}

dl.actionMenu.activated dd {
  // remove rounded corners, coming from authoring.css
  border-radius: 0;
}

/* BEGIN PCM-1331 */
.edit-bar-wrapper {
  display: none;
}
body.plone-toolbar-left-expanded {
  padding-left: 0;
}
/* END PCM-1331 */

/* Manage portlets styling, coming from controlpanel.css */

div.managePortletsLink,
a.managePortletsFallback {
  background-color: $brand-tertiary;
}

div.portletAssignments div.portletHeader {
  background-color: $brand-tertiary;
}

/* Related items styling */

.relatedItems {
    border-top: 1px solid $border-color;
    padding-top: $margin-default-vertical-mobile;
    @media(min-width: $screen-sm-min) {
      padding-top: $margin-default-vertical;
    }
    margin-top: $padding-large-mobile;
    @media(min-width: $screen-sm-min) {
      margin-top: $padding-large;
    }
    margin-bottom: $margin-default-vertical-mobile;
    @media(min-width: $screen-sm-min) {
      margin-bottom: $margin-default-vertical;
    }

	/* don't show file icon for pages in related items */
	.fa-file-o::before {
		content: "";
	}
}

/* Horizontal line above h2 */

#content-core,
.portaltype-subsitefolder.subsite-type-central_other_blank #homepage-content,
.template-fg_base_view_p3 #content {
  p + h2,
  ul + h2,
  ol + h2,
  table + h2,
  // in-page navigation pages, exclude the page extender div
  div[data-unique]:not(:first-child):not(.tocify-extend-page),
  div.pullquote + h2,
  div.blocks + h2,
  div.row + h2,
  div.panel-group + h2,
  blockquote + h2 {
    border-top: 1px solid $border-color;
    // padding and margin are normally applied to the bottom of divs, so they need
    // to be reversed here
    padding-top: $margin-default-vertical-mobile;
    @media(min-width: $screen-sm-min) {
      padding-top: $margin-default-vertical;
    }
    margin-top: $padding-large-mobile;
    @media(min-width: $screen-sm-min) {
      margin-top: $padding-large;
    }
  }
}

/* Horizontal lines with block structure on small screens */

#content-core,
.portaltype-subsitefolder.subsite-type-central_other_blank #homepage-content {
  @media(max-width: $screen-sm-min) {
    // horizontal lines above all h2's, except a h2 in the beginning of a block
    // in the beginning of a page
    div.blocks h2:first-child,
    div.blocks div[data-unique]:first-child + h2 {
      border-top: 1px solid $border-color;
      // padding and margin are normally applied to the bottom of divs, so they
      // need to be reversed here
      padding-top: $margin-default-vertical-mobile;
      margin-top: $padding-large-mobile;
    }
    div.blocks:first-child .col:first-child h2:first-child,
    div.blocks:first-child .col:first-child div[data-unique]:first-child + h2 {
      border-top: 0;
      padding-top: 0;
      margin-top: 0;
    }
  }
}

/* begin folder_constraintypes_form */

.template-folder_constraintypes_form {
	.field {
		margin-top: 1em;
		.fieldErrorBox {
			margin-bottom: 0.5em;
		}
	}

  	.option {
		.label {
      	  color: #000;
		  font-size: 100%;
		  font-weight: normal;
	    }
  	}

	.formControls {
		margin-top: 1em;
	}
}

/* end folder_constraintypes_form */


/* override styles for pagination */

#content ul.pagination {
    margin-left: 0;
    list-style-type: none;
}

#content ul.pagination li {
    display: inline-block;
    margin-bottom: 0;
}

.firstbatch {
    margin-right: 1em;
}

.lastbatch {
    margin-left: 1em;
}


/* remove border bottom on certain links */
a.noborder {
    border-bottom: 0;
}

/* specific styling for infofiche_view (PCM-743) */
.template-infofiche_view {
	#category {
		margin: 1em 0 2em;
	}
	
	.panel-body {
		padding-top: 1em;
		padding-bottom: 1em;
	}
}

/* PloneFormGen styling */
.PFGFieldsetWidget {
    margin-top: 2em;
}

#content li.formTab a.required span:after,
#content-core li.formTab a.required span:after,
div.field span.required:after {
    content:"";
}

div.easyformForm .fieldRequired {
    font-family: $light-font-family;
}

.formHelp {
    display: block;
    margin: 0 0 0.2em;
    font-family: $light-font-family;
}

.easyformForm {
    .label {
        border-radius: inherit;
        color: inherit;
        display: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        padding: inherit;
        text-align: inherit;
        vertical-align: inherit;
        white-space: inherit;
    }

    legend {
        margin-bottom: 1em;
    }

    input[type="radio"],
    input[type="checkbox"] {
        margin-right: 0.5em;
    }
}

/* End PloneFormGen styling */


/* Search results 
   Updated for PCM-1244 & PCM-1534 */


dl.actionMenu {
    float: right;
    white-space: nowrap;
    position: relative;
    margin-left: 10em;
}

dl.actionMenu a,
dl.actionMenu div {
    display: block !important;
    white-space: nowrap;
}
dl.actionMenu dt {
    font-weight: normal;
}
span.arrowDownAlternative {
    font-size: 80%;
}
dl.actionMenu.deactivated dt {
    margin: 0 0.25em;
}
dl.actionMenu.deactivated dd {
    display: none;
}
dl.actionMenu.activated dd {
    position: absolute;
    width: auto;
    min-width: 100%;
    border-bottom-right-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    -webkit-border-bottom-right-radius: 0.5em;
    -webkit-border-bottom-left-radius: 0.5em;
    -moz-border-radius-bottomleft: 0.5em;
    -moz-border-radius-bottomright: 0.5em;
}
dl.actionMenu.activated dt a,
dl.actionMenu.activated dd a,
dl.actionMenu.activated dd div {
    margin: 0 0.25em;
}

#sorting-options {
  a.active {
    font-weight: bold;
  }
}


#searchform {
  #search-results-bar {
    margin-bottom: 10px;
  }

  #search-results {
    padding: 0px;
    ul.resultlist {
      margin-left: 0px;
    }
  }

  #search-field.input-group {
    margin-bottom: 20px;
  }

  dt.actionMenuHeader, dl.actionMenu {
    margin-left: 0;
  }

  #search-filter {
	  position: absolute;
	  z-index: 1;
	  left: -6em;
	  margin: 0 0 2em 2em;
	  padding: 1em 1em 1em 2em;
	  background-color: $brand-tertiary;

	  legend {
		  font-weight: bold;
		  font-size: $fz-default;
		  border: 0;
		  margin-bottom: 0.5em;
	  }

	  fieldset {
		  margin-bottom: 1em;
	  }

    input {
      height: 0.9em;
    }
  }
}

@media(max-width: $screen-sm-min) {
    #searchResultsSort {
      margin-top: 30px;
    }

    #searchResultsFilter {
      margin-top: 20px;
    }

    #searchform #search-filter {
      left: -8.5em;
    }
}
/* End Search results styling */

/* Styling content history viewlet (PCM-516, PCM-902) */
#content-history {

    .link-overlay {
      display: inline-block;
      padding: 0 5px 5px 1em;
      margin-bottom: 1em;
      font-size: 90%;
      color: black;
      font-weight: 700;

      &::after {
        content: '↑';
        padding-left: 0.5em;
      }
    }

    .historyLinks {
        :first-child {
            margin-right: 1.5em;
        }
    }

    a {
        border-bottom: none;
    }

    ul li::before {
        content: "";
    }

    input[type="submit"] {
        margin-top: 0.3em;
    }

    a.historyComparePrevious {
        border-top: none;
    }
}
/* End Styling content history viewlet */

/* Styling ReferenceBrowserWidget */
.ArchetypesReferenceBrowserWidget {
    ul {
        padding-left: 0.5em;
    }
        li::before {
            content: "";
        }
    a {
        border: none;
    }
}

/* End Styling ReferenceBrowserWidget */

/* Live search */
#LSResult li::before {
    content: '';
}

.LSTable {
    padding-left: 0.5em;
}

/* End Live search */

/* slideshow portlet images widget */
#form-widgets-images-autocomplete {
    .label {
        border-radius: inherit;
        color: inherit;
        display: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        padding: inherit;
        text-align: inherit;
        vertical-align: inherit;
        white-space: inherit;
    }
}

#formfield-form-widgets-images {
    margin-top: 1em;
}

#form-widgets-images-contenttree-window {
    .navTreeLevel0 {
        padding-left: 0;
    }

    li::before {
        content: '';
    }
}

/* End slideshow portlet images widget */

/* info voor dropdown */
.pageheader .dropdown-nav {
    margin-left: 0;
}

/* folder_factories */
.folder_factories {

    input {
        margin-right: 0.5em;
    }

    dd {
        margin-bottom: 0.5em;
		margin-left: 3em;
    }
}

/* default error message */
.template-default_error_message #content-core h2 {
    margin-top: 1em;
    padding-top: 0.5em;
    border: 0;
}

/* search_form */
.optionsToggle {
	margin-bottom: 0.5em;

	label {
		background-color: $border-color-light;
		border-radius: 5px;
	}
}

/* make sure TinyMCE popups have a non-transparant top */
.plonepopup .mceTop, .plonepopup .mceTop div {
  background-color: $white;
}

/* criterium edit form */
.ArchetypesReferenceBrowserWidget .control-label {
  display: block;
}

/* select_default_page */
.template-select_default_page {
	.documentDescription {
		margin-bottom: 0.5em;
	}
	dt {
		label {
			margin-left: 0.5em;
		}
	}
}

/* image-left and image-rigth for small screens (full width) */
@media(max-width: $screen-ms-max) {
  .image-left {
      float: none;
      margin-right: 0;
  }
  .image-left {
      float: none;
      margin-left: 0;
  }
}

/* blog_view */
#blog-listing {
	margin-top: 1em;
}

/* Hide some of the Virtual Tree Categories UI (PCM-323) */
.ArchetypesVirtualTreeCategoriesWidget {
	.discreet {
		display: none;
	}
 }

#VTCFilterTree {
	display: none;
}

/* homepageNewsitems reference browser */
#atrb_homepageNewsitems, #atrb_focusNewsitem {
	.alert {
		padding: 11px;
	}
}

/* make overlay for focusNewsItem bigger (PCM-1227) */
#atrb_focusNewsitem.overlay-ajax {
      width: 75%;
}

#atrb_focusNewsitem {
  .overlaycontent {
    width: auto;
  }
}

/* ajax spinner */

#ajax-spinner { 
	display:none; 
	position: fixed; 
	background-position: center center; 
	top: 50%; 
	left: 50%; 
	margin-top: -10px; 
	margin-left: -10px; 
} 

/* sortable table styling (PCM-643, PCM-753) */
table.table-sortable {
	td:first-child {
		min-width: 120px;
	}
	
	td.field-getDefenseDateAndTime,
	td.field-EffectiveDate {
		white-space: nowrap;
	}
}

/* add more whitespace between items on folder_full_view (PCM-742) */

.template-folder_full_view {
	.item {
		margin-bottom: 2em;
	}
}

/* convert responsible e-mail to lowercase (PCM-771) */
.responsible {
	a[href^="mailto"] {
		text-transform: lowercase;
	}
}

/* Fix for floated image overlapping list in newsitem_view (PCM-870) */
.template-newsitem_view #content-core {
	ol {
		padding: 0;
		list-style-position: inside;
		
		li {
			overflow: hidden;
			padding-left: .5em;
		}
	}
}

/* eea.tags styling (PCM-802) */
ul.token-input-list-facebook {
  li {
    font-size: 13px;
  }
  li::before {
     content: "";
  }
}

/* Fix for button in searchbox */

.input-group-btn:last-child > .btn, 
.input-group-btn:last-child > .btn-group {
    min-width: 100%;
}

/* extra styling for font awesome icons in folder listings */

a .fa {
  margin-left: 5px;
}

/* styling date in collection portlet */
.portletItem .date {
  border: inherit;
  padding: inherit;
  text-align: inherit;
}

/* styling date on faceted-results */
#faceted-results .date {
  padding: none;
}

.homepage-blocks > div {
  margin-bottom: 1em;
  &>:first-child {
    padding-top: 2em;
    border-top: 1px solid #ccc;
  }
  &:first-child>:first-child { border-top: none; }
}
@media only screen and (min-width: $screen-sm-min) {
  .homepage-blocks > div:nth-child(2) > :first-child {
    border-top: none;
  }
}
